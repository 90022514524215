@import "ClientApp/styles/base/base.scss";
@import 'haypp/index.scss';
$haypp-blue-dark-0-clr: darken($clr-blue, 5%);
$haypp-blue-dark-1-clr: darken($clr-blue, 10%);
$haypp-blue-light-0-clr: darken($clr-blue-light, 10%);
$haypp-footer-top-bg-clr: darken($clr-blue-light, 5%);

/* THEME */
[data-theme='haypp'] {
  --logo: url('~@assets/images/haypp-logo.svg');
  --logo-height: 34px;
  --logo-width: 187px;

  --logo-mobile: url('~@assets/images/haypp-logo.svg');
  --logo-height-mobile: 25px;
  --logo-width-mobile: 140px;

  --footer-bg-color: var(--color-blue);
  --footer-bottom-header-color: var(--color-blue-lighter);

  --footer-usp-bg-color: #{$haypp-blue-dark-0-clr};

  --footer-top-bg-color: #{$haypp-footer-top-bg-clr};
  --footer-top-text: var(--color-black);

  --footer-bottom-text-color: var(--color-white);
  --element-radius: 0;

  main {
    margin-bottom: 50px;
    @media (min-width: $screen-small) {
      margin-bottom: 100px;
    }
  }

  --button-primary-background-color: #{$haypp-blue-dark-0-clr};
  --button-primary-background-color-hover: #{$haypp-blue-dark-1-clr};
  --button-primary-background-color-active: #{$haypp-blue-dark-1-clr};
  --button-secondary-background-color: #{$clr-blue-light};
  --button-secondary-background-color-hover: #{$haypp-blue-light-0-clr};
  --button-secondary-background-color-active: #{$haypp-blue-light-0-clr};

  --productpage-attributes-background-color: var(--color-blue);

  --banner-button-bg-color: #{$haypp-clr-green-light};
  --banner-button-font-weight: 600;
  --banner-title-font-weight: 600;
  --banner-subtitle-font-weight: 400;
  --banner-subtitle-font-style: italic;
  --gotocheckout-button-bg-color: #{$haypp-clr-green-bright};
}
