[data-theme='haypp'] {
  footer {
    .footer-top {
      .footer-puff-image {
        visibility: hidden;
        display: none;
      }

      .footer-puff-headline {
        color: var(--font-color-light);
      }

      .footer-puff-text {
        color: var(--font-color-light);
      }

      .label-h3 {
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 30px;
        margin-bottom: var(--padding-xsmall);
      }

      &::before,
      &::after {
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        height: 110px;
        width: 100px;
        content: '';
        z-index: 1;
      }

      &::before {
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='180px' height='200px' viewBox='0 0 339 371' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 52.5 (67469) - http://www.bohemiancoding.com/sketch --%3E%3Ctitle%3Edosa_left%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='dosa_left' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M339,84 L0,84 L0,286.264234 C0,333.241733 151.774439,371.325562 339,371.325562 L339,84 Z' id='Fill-1' fill='%23809EB7' transform='translate(169.500000, 227.662781) scale(-1, 1) translate(-169.500000, -227.662781) '%3E%3C/path%3E%3Cpath d='M339,238.071241 C308.886382,238.119416 278.759674,237.317959 248.720228,235.399718 C218.654603,233.753009 188.689327,230.713605 158.876758,226.728218 C129.107819,222.550132 99.4392294,217.193948 70.6301564,208.824636 C56.2103493,204.74728 42.0174197,199.7327 28.753815,192.856814 C22.2049102,189.32252 15.8436156,185.319616 10.4465501,180.217445 C5.05384769,175.233523 0.58174413,168.708 0.0145505084,161 C-0.269046302,168.71238 3.62713758,176.12695 8.71006503,181.894811 C13.8977052,187.702089 20.1717392,192.340027 26.6333835,196.526873 C39.7224671,204.685966 53.8368622,210.957475 68.1955869,216.283002 C97.0570161,226.631868 126.891401,233.74863 156.930847,239.065398 C217.053371,249.812802 278.092131,253.793809 339,254.065341 L339,238.071241 Z' id='Fill-4' fill='%23E5EDF2' transform='translate(169.500000, 207.532670) scale(-1, 1) translate(-169.500000, -207.532670) '%3E%3C/path%3E%3Cpath d='M339,0 C151.774439,0 0,38.0370445 0,84.9622301 C0,131.887416 151.774439,169.928834 339,169.928834 L339,0 Z' id='Fill-7' fill='%23FFFFFF' transform='translate(169.500000, 84.964417) scale(-1, 1) translate(-169.500000, -84.964417) '%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
        left: 0;
        top: -50px;
      }

      &::after {
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='180px' height='200px' viewBox='0 0 339 371' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 52.5 (67469) - http://www.bohemiancoding.com/sketch --%3E%3Ctitle%3Edosa_right%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='dosa_right' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M339,84 L0,84 L0,286.264234 C0,333.241733 151.774439,371.325562 339,371.325562 L339,84 Z' id='Fill-1' fill='%23E5EDF2'%3E%3C/path%3E%3Cpath d='M339,238.071241 C308.886382,238.119416 278.759674,237.317959 248.720228,235.399718 C218.654603,233.753009 188.689327,230.713605 158.876758,226.728218 C129.107819,222.550132 99.4392294,217.193948 70.6301564,208.824636 C56.2103493,204.74728 42.0174197,199.7327 28.753815,192.856814 C22.2049102,189.32252 15.8436156,185.319616 10.4465501,180.217445 C5.05384769,175.233523 0.58174413,168.708 0.0145505084,161 C-0.269046302,168.71238 3.62713758,176.12695 8.71006503,181.894811 C13.8977052,187.702089 20.1717392,192.340027 26.6333835,196.526873 C39.7224671,204.685966 53.8368622,210.957475 68.1955869,216.283002 C97.0570161,226.631868 126.891401,233.74863 156.930847,239.065398 C217.053371,249.812802 278.092131,253.793809 339,254.065341 L339,238.071241 Z' id='Fill-4' fill='%23809EB7'%3E%3C/path%3E%3Cpath d='M339,0 C151.774439,0 0,38.0370445 0,84.9622301 C0,131.887416 151.774439,169.928834 339,169.928834 L339,0 Z' id='Fill-7' fill='%23FFFFFF'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
        bottom: -50px;
        right: 0;
      }

      @media (min-width: $screen-small) {
        &::before,
        &::after {
          height: 200px;
          width: 140px;
        }

        &::before {
          top: -100px;
        }

        &::after {
          bottom: -100px;
        }
      }
    }

    .footer-usp-block {
      //--footer-bottom-header-color: var(--color-blue-lighter);

      .label-h4 {
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 24px;
        margin-bottom: var(--padding-xsmall);
        color: var(--color-blue-lighter);
      }

      p {
        color: var(--font-color-light);
      }

      .footer-usp-customerServiceImg {
        @include svg-transform($clr-blue-lighter, #{$icon-usp-customerservice});
      }

      .footer-usp-shippingImg {
        @include svg-transform($clr-blue-lighter, #{$icon-usp-shipping});
      }

      .footer-usp-discountsImg {
        @include svg-transform($clr-blue-lighter, #{$icon-usp-discounts});
      }

      .footer-usp-freshProductsImg {
        @include svg-transform($clr-blue-lighter, #{$icon-usp-freshproducts});
      }
    }
  }
}
