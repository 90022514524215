﻿@use 'sass:math';
@use 'sass:string';
@use 'sass:meta';
@use 'sass:color';

@mixin fluid-size($minBreakpoint, $maxBreakpoint, $minFont, $maxFont) {
  /* Define variable for media query */
  $maxLessOne: $maxBreakpoint - 1;

  /* Define variable for fallback */
  $avg: calc(($maxFont + $minFont) / 2);

  /* Base font size */
  font-size: #{$minFont}px;

  @media (min-width: #{$minBreakpoint}px) and (max-width: #{$maxLessOne}px) {
    /* Adds a fallback for unsupported browsers */
    font-size: #{$avg}px;

    /* The fluid typography magic 🌟  */
    font-size: calc(
      #{$minFont}px + (#{$maxFont} - #{$minFont}) * (100vw - #{$minBreakpoint}px) /
        (#{$maxBreakpoint} - #{$minBreakpoint})
    );
  }

  @media (min-width: #{$maxBreakpoint}px) {
    font-size: #{$maxFont}px;
  }
}

@mixin fluid-prop-size($property, $min-vw, $max-vw, $min-size, $max-size) {
  #{$property}: $min-size;

  @media screen and (min-width: $min-vw) {
    #{$property}: calc($min-size + ($max-size - $min-size) * (100vw - $min-vw) / ($max-vw - $min-vw));
  }

  @media screen and (min-width: $max-vw) {
    #{$property}: $max-size;
  }
}

@mixin hidden() {
  visibility: hidden;
  display: none;
  pointer-events: none;
}

@mixin visible() {
  visibility: visible;
  display: block;
  pointer-events: visible;
}

@mixin button-size($padding-horizontal, $height) {
  height: $height;
  padding: 0 $padding-horizontal;
}

@mixin section-shadow() {
  background-color: var(--color-white);
  padding: var(--padding-small);
  box-shadow: 0 1.1rem 2rem rgba(0, 0, 0, 0.1);
  border-radius: 6px;

  input[type='text'], .dropdown.select-checkout {
    // background-color: var(--color-grey-lighter);
    background-color: transparent;
    border: solid 1px rgb(214, 214, 214);
    border-radius: 2px;
    outline: currentcolor none medium;

    &:focus-within {
      border: solid 1px rgb(150, 147, 145);
    }
  }
}

@mixin place-self-start() {
  place-self: start start;
}

@mixin place-self-center() {
  place-self: center center;
}

@mixin flex-center() {
  justify-content: center;
}

@mixin flex-start() {
  justify-content: flex-start;
}

@mixin flex-end() {
  justify-content: flex-end;
}

@mixin flex() {
  display: flex;
  align-items: center;
}

@mixin container($padding) {
  display: block;
  margin: 0 auto var(--padding-small);
  padding: 0 $padding;

  @media (min-width: $screen-medium) {
    margin: 0 auto var(--padding-medium);
    padding: 0 var(--padding-medium);
  }

  @media (min-width: $screen-large) {
    margin: 0 auto var(--padding-medium);
    padding: 0 var(--padding-medium);
  }
}

@mixin svg-transform($color, $data) {
  background-image: svg-load($data, fill=$color);
}

@mixin svg-transform-stroke($color, $data) {
  background-image: svg-load($data, stroke=$color);
}

@function strip-units($number) {
  @return math.div($number, ($number * 0 + 1));
}

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}
